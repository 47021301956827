import { genericApiCall, apiCall } from "./api";

export const authenticate = (email, password, emailConfirmationCode = "") =>
  genericApiCall("POST", "api/account/authenticate", {
    email,
    password,
    emailConfirmationCode,
  });

export const onboard = (password, token) =>
  genericApiCall("POST", "api/account/onboard-user", {
    password,
    token,
  });

export const forgetPass = (email) =>
  genericApiCall("POST", "api/account/forgot-password", {
    email,
  });

export const impersonate = (orgId) =>
  apiCall("POST", "api/v1/User/impersonate", {
    organizationId: orgId,
  });

export const resetPass = (email, token, password) =>
  genericApiCall("POST", "api/account/reset-password", {
    email,
    token,
    newPassword: password,
    confirmPassword: password,
  });

  export const getOnboardingStatus = (email) => genericApiCall('GET',`api/Account/onboarding-available?email=${email}`)

  export const resendEmailConfirmationToken = (email) =>  genericApiCall("POST", "api/Account/resend-email-confirmation-code", {
    email,
  });