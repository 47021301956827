import jwt_decode from 'jwt-decode';
import {
  authenticate,
  onboard,
  forgetPass,
  resetPass,
  impersonate,
  getOnboardingStatus,
  resendEmailConfirmationToken,
} from '@/services/api/auth';
import {
  getSubscriptionDetails
} from '@/services/api/subscriptions';
import router from '@/router';

const getDefaultState = () => ({
  token: null,
  impersonateToken: null,
  decodedData: null,
  impersonateDecodedData: null,
  onboardingStatus: null,
  subDetails: null
});

const actions = {
  resetCallsState({ commit }) {
    commit('resetState');
  },
  async cleanImpersonation(
    { commit, state, dispatch },
    { toCompany, otherPath }
  ) {
    const oldCompanyId = state.impersonateDecodedData['organization-id'];
    await commit('SET_IMPERSONATE_TOKEN', null);
    await commit('SET_IMPERSONATE_DECODED_DATA', null);
    const decoded = await jwt_decode(state.token);
    await dispatch(
      'home/setUserRoles',
      { roles: Array.isArray(decoded.roles) ? decoded.roles : [decoded.roles] },
      { root: true }
    );
    await dispatch('home/setEmail', { email: decoded.email }, { root: true });
    await dispatch(
      'home/setUserId',
      { id: parseInt(decoded.id, 10) },
      { root: true }
    );
    if (otherPath) {
      router.push(otherPath);
    } else if (toCompany) {
      router.push(`/company/${oldCompanyId}/overview`);
    } else if (router.history.current.fullPath !== '/') {
      router.push('/');
    }
  },
  async getUserSubscriptionDetails({ commit }, organizationId) {
    const details = await getSubscriptionDetails(organizationId);
    const { data } = details.data;
    commit("SET_SUB_DETAILS", data);
  },
  getOnboardingStatus({ commit }, { email }) {
    return getOnboardingStatus(email).then((rsp) => {
      commit('SET_ONBOARDING_STATUS', rsp.data);
    });
  },
  impersonateAdmin({ commit, dispatch }, { orgId }) {
    return impersonate(orgId).then((rsp) => {
      commit('SET_IMPERSONATE_TOKEN', rsp.data.data.accessToken);
      const decoded = jwt_decode(rsp.data.data.accessToken);
      commit('SET_IMPERSONATE_DECODED_DATA', decoded);
      dispatch(
        'home/setUserRoles',
        { roles: ['CompanyAdmin'] },
        { root: true }
      );
      dispatch('home/setEmail', { email: decoded.email }, { root: true });
      dispatch(
        'home/setUserId',
        { id: parseInt(decoded.id, 10) },
        { root: true }
      );
      router.push('/');
    });
  },
  resendEmailConfirmationToken({dispatch}, {email}) {
    return resendEmailConfirmationToken(email).then(rsp => {
      if(rsp.data.data) {
        dispatch(
          'alerts/showInfo',
          {
            messageHeader: 'Email confirmation token sent',
            message:
              `Email confirmation token sent`,
          },
          { root: true }
        );
      }
    })
  },
  login({ commit, dispatch }, { email, password, noRedirect, emailConfirmationCode }) {
    return authenticate(email, password, emailConfirmationCode)
      .then((rsp) => {
        const { data } = rsp;
        if(data.data.requireEmailConfirmation) {
          return -1;
        }
        dispatch(
          'home/setUserName',
          { firstName: data.data.firstName, lastName: data.data.lastName },
          { root: true }
        );
        dispatch(
          'home/setUserRoles',
          { roles: data.data.roles },
          { root: true }
        );
        dispatch('home/setEmail', { email: data.data.email }, { root: true });
        dispatch('home/setUserId', { id: data.data.id }, { root: true });
        dispatch(
          'home/setUserPosition',
          { position: data.data.jobTitle },
          { root: true }
        );
        commit('SET_TOKEN', data.data.jwToken);
        const decoded = jwt_decode(data.data.jwToken);
        commit('SET_DECODED_DATA', decoded);
        if (data.data.roles.includes('SellerAdmin')) {
          commit('SET_SUB_DETAILS', { subscriptionStatus: 1 });
        } else {
          dispatch('getUserSubscriptionDetails', parseInt(decoded['organization-id'], 10));
        }
        if (!noRedirect) router.push('/');
      })
      .catch((err) => {
        const error = JSON.parse(err.message);
        dispatch(
          'alerts/showError',
          {
            messageHeader: error?.Title || 'Invalid credentials',
            message:
              error?.Message ||
              error ||
              'The credentials you submitted doesn’t match any account on the platform.',
          },
          { root: true }
        );
      });
  },
  confirmAccount({ dispatch }, { email, password, token, noRedirect }) {
    return onboard(password, token).then(async () => {
      await dispatch('login', { email, password, noRedirect });
    })
    .catch((err) => {
      let error = err;
        try {
          error = JSON.parse(err.message);
        } catch { }

        dispatch(
          'alerts/showError',
          {
            messageHeader: error?.Title || 'Error',
            message:
              error?.Message ||
              error ||
              'Something went wrong. Please try again or contact the administrator.',
          },
          { root: true }
        );
    });
  },
  forgotPassword({ dispatch }, { email }) {
    forgetPass(email)
      .then((rsp) => {
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'Email sent',
            message: rsp.data.message,
          },
          { root: true }
        );
      })
      .catch((e) => {
        let error = {title: '', message: ''}
        try {
          const parsedError = JSON.parse(e.toString().substr(7));
          error.title = parsedError.Title;
          error.message = parsedError.Message;
        } catch {}
        dispatch(
          'alerts/showError',
          {
            messageHeader: error.title || 'Invalid email',
            message:
            error.message || 'The email address you submitted doesn’t match any account on the platform.',
          },
          { root: true }
        );
      });
  },
  resetPassword({ dispatch }, { email, token, password, skipLogin = false }) {
    return resetPass(email, token, password)
      .then(async () => {
        if(!skipLogin) {
          await dispatch('login', { email, password });
        } else {
          dispatch(
            'alerts/showSuccess',
            {
              messageHeader: 'Password updated',
              message: 'Password updated. Please go to sign in and use your new credentials',
            },
            { root: true }
          );
        }
        return true;
      })
      .catch((err) => {
        let error = err;
        try {
          error = JSON.parse(err.message);
        } catch { }

        dispatch(
          'alerts/showError',
          {
            messageHeader: error?.Title || 'Error',
            message:
              error?.Message ||
              error ||
              'Something went wrong. Please try again or contact the administrator.',
          },
          { root: true }
        );
        return false;
      });
  },
  logout({ commit, dispatch }, { doRedirect }) {
    commit('REMOVE_TOKEN');
    commit('SET_IMPERSONATE_TOKEN', null);
    commit('SET_IMPERSONATE_DECODED_DATA', null);
    dispatch('home/resetUserState', null, { root: true });
    dispatch('assessments/resetAssessmentsState', null, { root: true });
    if (doRedirect) router.push('/login');
  },
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_ONBOARDING_STATUS(state, payload) {
    state.onboardingStatus = payload;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_DECODED_DATA(state, decodedData) {
    state.decodedData = decodedData;
  },
  SET_IMPERSONATE_TOKEN(state, token) {
    state.impersonateToken = token;
  },
  SET_IMPERSONATE_DECODED_DATA(state, decodedData) {
    state.impersonateDecodedData = decodedData;
  },
  REMOVE_TOKEN(state) {
    state.token = null;
  },
  SET_SUB_DETAILS(state, data) {
    state.subDetails = data;
  },
};

const getters = {
  getToken: (state) => state.token,
  isLogged: (state) => !!state.token,
  isSeller: (_, getters, rootState, rootGetters) =>
    rootGetters['home/getRole'] === 'seller' &&
    rootState.companies.currentCompany.id,
  isImpersonating: (state) => !!state.impersonateToken,
  getImpersonateToken: (state) => state.impersonateToken,
  isSubscriptionActive: state => state.subDetails?.subscriptionStatus === 1,
  subscriptionDetails: state => state.subDetails,
  getOrganizationId: (state, getters) => {
    if (getters.isImpersonating)
      return parseInt(state.impersonateDecodedData['organization-id'], 10);
    return parseInt(state.decodedData['organization-id'], 10);
  },
  getOrganizationName: (state, getters) => {
    if (getters.isImpersonating)
      return state.impersonateDecodedData['organizationName'];
    return state.decodedData['organizationName'];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
