import {
  getProducts,
  getDrafts,
  saveProductDetails,
  editProductDetails,
  getProductById,
  createCompetency,
  getSurveyCompetencies,
  cloneCompetencies,
  getCompetencyById,
  editCompetency,
  deactivateCompetency,
  activateCompetency,
  cloneJobRoles,
  createJobRole,
  getJobRoleById,
  editJobRole,
  editJobRoleTargets,
  updateScoreSettings,
  getScoreSettings,
  setCompetencyQuestion,
  getSurveyCommunication,
  uploadSurveyImage,
  updateSurveyCommunication,
  getSurveyImage,
  getOrgTargets,
  updateOrgTargets,
  publishProduct,
  createNewVersion,
  cloneProduct,
  retireProduct,
  getVersionHistoryProducts,
  editLaunchDate,
  changeJobRoleStatus,
  deleteDraftProduct
} from "@/services/api/products";
import { getImageDataByType } from '../components/SurveyCommunication/imageUtils';
import { getRetiredProducts } from "../../../services/api/products";

const getDefaultState = () => ({
  products: [],
  drafts: [],
  versionHistoryProducts: [],
  productDetails: {
    name: "",
    description: "",
    maxTimeAllocation: "",
    duration: "",
    productName: "",
    versionReleaseNotes: "",
  },
  competency: {
    name: "",
    description: "",
    color: "#009999",
    subCompetencies: [{ name: "", id: "1" }],
    active: ""
  },
  jobRole: {
    name: "",
    description: "",
    jobTitles: [],
    targets: [],
  },
  surveyCompetencies: [],
  scores: [],
  questions: [],
  communication: [],
  introImage: null,
  orgTargets: [],
});

const state = getDefaultState();

const actions = {
  resetProductDetails({ commit }) {
    commit("RESET_PRODUCT_DETAILS");
  },
  resetCompetencyDetails({ commit }) {
    commit("RESET_COMPETENCY_DETAILS");
  },
  publishProduct({ dispatch }, { id, payload, flag }) {
    return publishProduct(id, payload).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: flag ? "Product Launch Scheduled " : "Product Launched",
          message: flag ? " You have successfully scheduled the launch for a new product." : "You have successfully launched a new product.",
        },
        { root: true }
      );
      return rsp.data.data;
    });
  },
  editLaunchDate({ dispatch }, { id, payload }) {
    return editLaunchDate(id, payload).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Launch Date Edited",
          message: "You have successfully edited the product launch date.",
        },
        { root: true }
      );
      return rsp.data.data;
    });
  },
  getProducts({ commit }) {
    return getProducts().then((rsp) => {
      commit("SET_PRODUCTS", rsp.data.data);
    });
  },
  getDrafts({ commit }) {
    return getDrafts().then((rsp) => {
      const draftsList = rsp?.data?.data?.sort(
        (a, b) => b.latestVersion - a.latestVersion
      );
      commit("SET_DRAFTS", draftsList);
    });
  },
  getVersionHistoryProducts({ commit }) {
    return getVersionHistoryProducts().then((rsp) => {
      commit("SET_VERSION_HISTORY_PRODUCTS", rsp.data.data);
    });
  },
  getRetiredProducts({ commit }) {
    return getRetiredProducts().then((rsp) => {
      commit("SET_RETIRED_PRODUCTS", rsp.data.data);
    });
  },
  saveProductDetails({ commit }, payload) {
    return saveProductDetails(payload).then((rsp) => {
      const {
        createdBy,
        id,
        canPublish,
        cantPublishMessage,
        isReadOnly,
        productVersions,
        publishDate,
        name,
        currentVersion,
        survey,
      } = rsp.data.data;
      commit("SET_PRODUCT_DETAILS", {
        createdBy,
        productId: id,
        canPublish,
        cantPublishMessage,
        isReadOnly,
        productVersions,
        publishDate,
        productName: name,
        currentVersion,
        ...survey,
      });
      return id;
    });
  },
  editProductDetails({ dispatch }, { id, payload }) {
    return editProductDetails(id, payload).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Product Details Edited",
          message: "You have successfully edited the product details.",
        },
        { root: true }
      );
      return rsp.data.data.id;
    });
  },
  editCompetency({ dispatch }, { id, payload }) {
    return editCompetency(id, payload).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Competency Edited",
          message: "You have successfully edited the competency.",
        },
        { root: true }
      );
      return rsp.data.data;
    });
  },
  deactivateCompetency({ dispatch, commit }, { id }) {
    return deactivateCompetency(id)
      .then((rsp) => {
        dispatch(
          "alerts/showSuccess",
          {
            messageHeader: "Competency Deactivated",
            message: "You have successfully deactivated the competency.",
          },
          { root: true }
        );
        commit("SET_COMPETENCY_STATUS", false);
        return rsp.data.data;
      })
      .catch((err) => {
        const errorMessage = JSON.parse(err.message);
        dispatch(
          "alerts/showError",
          {
            messageHeader: 'Deactivate Error',
            message: errorMessage[0],
          },
          { root: true }
        );
      });
  },
  activateCompetency({ dispatch, commit }, { id }) {
    return activateCompetency(id).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Competency Activated",
          message: "You have successfully activated the competency.",
        },
        { root: true }
      );
      commit("SET_COMPETENCY_STATUS", true);
      return rsp.data.data;
    });
  },
  getProductById({ commit }, { id, version }) {
    return getProductById(id, version).then((rsp) => {
      const {
        createdBy,
        id,
        canPublish,
        cantPublishMessage,
        isReadOnly,
        productVersions,
        publishDate,
        name,
        currentVersion,
        survey,
        releaseNotes,
        draftVersion,
        endDate,
        retiredDate,
      } = rsp.data.data;
      commit("SET_PRODUCT_DETAILS", {
        createdBy,
        productId: id,
        canPublish,
        cantPublishMessage,
        isReadOnly,
        productVersions,
        publishDate,
        endDate,
        productName: name,
        currentVersion,
        releaseNotes,
        draftVersion,
        retiredDate,
        ...survey,
      });
      return id;
    });
  },
  getCompetencyById({ commit }, id) {
    return getCompetencyById(id).then((rsp) => {
      commit("SET_COMPETENCY_DETAILS", rsp.data.data);
    });
  },
  setProductName({ commit }, { name }) {
    commit("SET_PRODUCT_NAME", name);
  },
  setArrivedToProductsEditFrom({ commit }, placeArrivedFrom) {
    const isValidEnumValue = Object.values(PlaceArrivedFromEnum).includes(placeArrivedFrom);

    if (!isValidEnumValue)
      placeArrivedFrom = PlaceArrivedFromEnum.PRODUCTS;

    commit("SET_ARRIVED_FROM", placeArrivedFrom);
  },
  setProductReleaseNotes({ commit }, releaseNotes) {
    commit("SET_PRODUCT_VERSION_NOTES", releaseNotes);
  },
  setProductNameForProduct({ commit }, { name }) {
    commit("SET_PRODUCT_NAME_FOR_PRODUCT", name);
  },
  setProductDescription({ commit }, { description }) {
    commit("SET_PRODUCT_DESCRIPTION", description);
  },
  setTimeAllocation({ commit }, { time }) {
    commit("SET_TIME_ALLOCATION", time);
  },
  setAssessmentDuration({ commit }, { time }) {
    commit("SET_ASSESSMENT_DURATION", time);
  },
  setCompetencyName({ commit }, { name }) {
    commit("SET_COMPETENCY_NAME", name);
  },
  setCompetencyDescription({ commit }, { description }) {
    commit("SET_COMPETENCY_DESCRIPTION", description);
  },
  setCompetencyColor({ commit }, { color }) {
    commit("SET_COMPETENCY_COLOR", color);
  },
  setSubCompetency({ commit }, payload) {
    commit("SET_SUB_COMPETENCY", payload);
  },
  removeSubCompetency({ commit }, payload) {
    commit("REMOVE_SUB_COMPETENCY", payload);
  },
  addSubCompetency({ commit }) {
    commit("ADD_SUB_COMPETENCY");
  },
  createCompetency({ commit }, payload) {
    return createCompetency(payload).then((rsp) => {
      commit("CREATE_COMPETENCY", rsp.data.data);
    });
  },
  getSurveyCompetencies({ commit }, id) {
    return getSurveyCompetencies(id).then((rsp) => {
      commit("SET_SURVEY_COMPETENCIES", rsp.data.data);
    });
  },
  resetSurveyCompetenies({ commit }) {
    commit("SET_SURVEY_COMPETENCIES", []);
  },
  cloneCompetencies({ dispatch }, payload) {
    return cloneCompetencies(payload).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Competencies Copied",
          message: "You have successfully copied the selected competencies.",
        },
        { root: true }
      );
      return rsp.data.data.id;
    });
  },
  cloneJobRoles({ dispatch }, { id, payload }) {
    return cloneJobRoles(id, payload).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Job Roles Copied",
          message: "You have successfully copied the selected job roles.",
        },
        { root: true }
      );
      return rsp.data.data;
    });
  },
  setJobRoleName({ commit }, { name }) {
    commit("SET_JOB_ROLE_NAME", name);
  },
  setJobRoleDescription({ commit }, { description }) {
    commit("SET_JOB_ROLE_DESCRIPTION", description);
  },
  changeJobRoleStatus({ dispatch, state }, { id, isActive }) {
    return changeJobRoleStatus(id, isActive)
      .then((rsp) => {
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: `Job Role(s) ${isActive ? 'Activated' : 'Deactivated'}`,
            message: `The selected job role(s) have been ${isActive ? 'activated' : 'deactivated'} for the system.`,
          },
          { root: true }
        );
        dispatch('setJobRoleStatus', {
          jobRole: { ...state.jobRole, isActive },
        });
      })
      .catch((err) =>
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: err.message,
          },
          { root: true }
        )
      );
  },
  setJobRoleStatus({ commit }, { jobRole }) {
    commit('SET_JOB_ROLE_STATUS', jobRole);
  },
  setJobTitles({ commit }, { jobTitles }) {
    commit("SET_JOB_TITLES", jobTitles);
  },
  createJobRole({ commit }, payload) {
    return createJobRole(payload).then((rsp) => {
      commit("CREATE_JOB_ROLE", {
        ...rsp.data.data,
        jobTitles: rsp.data.data.jobTitles
          ? rsp.data.data.jobTitles.split(",")
          : [],
      });
      return rsp.data.data.id;
    });
  },
  getJobRoleById({ commit }, id) {
    return getJobRoleById(id).then((rsp) => {
      commit("SET_JOB_ROLE_DETAILS", {
        ...rsp.data.data,
        jobTitles: rsp.data.data.jobTitles
          ? rsp.data.data.jobTitles.split(",")
          : [],
      });
    });
  },
  resetJobRoleDetails({ commit }) {
    commit("RESET_JOB_ROLE_DETAILS");
  },
  editJobRole({ dispatch }, payload) {
    return editJobRole(payload).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Job Role Edited",
          message: "You have successfully edited the job role.",
        },
        { root: true }
      );
      return rsp.data.data;
    });
  },
  editJobRoleTargets({ dispatch }, { id, payload }) {
    return editJobRoleTargets(id, payload).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Job Role Targets Edited",
          message: "You have successfully edited the job role targets.",
        },
        { root: true }
      );
      return rsp.data.data;
    });
  },
  setScoreName({ commit }, payload) {
    commit("SET_SCORE_NAME", payload);
  },
  setScoreValue({ commit }, payload) {
    commit("SET_SCORE_VALUE", payload);
  },
  setScores({ commit }, payload) {
    commit("SET_SCORES", payload);
  },
  resetScores({ commit }) {
    commit("RESET_SCORES");
  },
  updateScoreSettings({ commit, dispatch }, { id, payload }) {
    return updateScoreSettings(id, payload)
      .then((rsp) => {
        const scores = rsp?.data?.data?.map((item) => ({
          name: item.name,
          score: item.score,
          id: item.id,
        }));
        commit("SET_SCORES", scores);
        return true;
      })
      .catch((err) => {
        let error = JSON.parse(err.message);
        if (typeof error === "object") error = error[0];
        dispatch(
          "alerts/showError",
          {
            messageHeader: error?.Title || "Error",
            message:
              error?.Message ||
              error ||
              "Something went wrong. Please try again or contact the administrator.",
          },
          { root: true }
        );
      });
  },
  getScoreSettings({ commit }, id) {
    return getScoreSettings(id).then((rsp) => {
      commit("SET_SCORES", rsp.data.data);
    });
  },
  setDefaultQuestions({ commit }) {
    commit("SET_DEFAULT_QUESTIONS");
  },
  setFilteredQuestions({ commit }, payload) {
    commit("SET_FILTERED_QUESTIONS", payload);
  },
  setQuestionText({ commit }, payload) {
    commit("SET_QUESTION_TEXT", payload);
  },
  setResponseText({ commit }, payload) {
    commit("SET_RESPONSE_TEXT", payload);
  },
  setCompetencyQuestion({ dispatch }, { id, payload }) {
    return setCompetencyQuestion(id, payload).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Question Added",
          message: "You have successfully added a new question.",
        },
        { root: true }
      );
      return rsp.data.data;
    });
  },
  getSurveyCommunication({ commit }, id) {
    return getSurveyCommunication(id).then((rsp) => {
      commit("SET_SURVEY_COMMUNICATION", rsp.data.data);
    });
  },
  uploadSurveyImage({ dispatch }, { id, file }) {
    return uploadSurveyImage(id, file).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Intro Image Added",
          message: "You have successfully added the intro image.",
        },
        { root: true }
      );
      return rsp.data.data;
    });
  },
  setCommunicationMessage({ commit }, payload) {
    commit("SET_COMMUNICATION_MESSAGE", payload);
  },
  setEmailSubject({ commit }, payload) {
    commit("SET_EMAIL_SUBJECT", payload);
  },
  setEmailAudience({ commit }, payload) {
    commit("SET_EMAIL_AUDIENCE", payload);
  },
  setEmailFrequency({ commit }, payload) {
    commit("SET_EMAIL_FREQUENCY", payload);
  },
  updateSurveyCommunication({ dispatch }, { id, payload }) {
    return updateSurveyCommunication(id, payload).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Communication Updated",
          message: "You have successfully updated the survey communication.",
        },
        { root: true }
      );
      return rsp.data.data;
    });
  },
  getSurveyImage({ commit }, { id, type }) {
    const typeFromEnum = getImageDataByType(type).type;
    return getSurveyImage(id, typeFromEnum).then((rsp) => {
      commit("SET_INTRO_IMAGE", { payload: rsp.data.data, type });
    });
  },
  resetIntroImage({ commit }) {
    commit("RESET_INTRO_IMAGE");
  },
  getOrgTargets({ commit }, id) {
    return getOrgTargets(id).then((rsp) => {
      commit("GET_ORG_TARGETS", rsp.data.data);
    });
  },
  updateOrgTargets({ dispatch }, { id, payload }) {
    return updateOrgTargets(id, payload).then((rsp) => {
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Targets Updated",
          message: "You have successfully updated the organizational targets.",
        },
        { root: true }
      );
      return rsp.data.data;
    });
  },
  createNewVersion({ dispatch }, payload) {
    return createNewVersion(payload)
      .then((rsp) => {
        dispatch(
          "alerts/showSuccess",
          {
            messageHeader: "New Version Created",
            message:
              "You have successfully created a new version of the assessment.",
          },
          { root: true }
        );
        return rsp.data.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  cloneProduct({dispatch}, payload) {
    return cloneProduct(payload)
      .then((rsp) => {
        dispatch(
          "alerts/showSuccess",
          {
            messageHeader: "Product Cloned",
            message:
              "You have successfully cloned the selected product.",
          },
          { root: true }
        );
        return rsp.data.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  retireProduct({ dispatch }, id) {
    return retireProduct(id)
      .then((rsp) => {
        dispatch(
          "alerts/showSuccess",
          {
            messageHeader: "Product retired",
            message:
              "You have successfully retired this product.",
          },
          { root: true }
        );
        return rsp.data.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  deleteDraftProduct({ dispatch }, id) {
    return deleteDraftProduct(id)
      .then((rsp) => {
        dispatch(
          "alerts/showSuccess",
          {
            messageHeader: "Draft Product Deleted",
            message:
              "You have successfully deleted the product draft.",
          },
          { root: true }
        );
        return rsp.data.data;
      })
      .catch((err) => {
        throw err;
      });
  },
};

const mutations = {
  RESET_PRODUCT_DETAILS(state) {
    state.productDetails = {
      name: "",
      description: "",
      maxTimeAllocation: "",
      duration: "",
      productName: "",
    };
  },
  RESET_COMPETENCY_DETAILS(state) {
    state.competency = {
      name: "",
      description: "",
      color: "#009999",
      subCompetencies: [{ name: "", id: 1 }],
      active: "",
    };
  },
  RESET_JOB_ROLE_DETAILS(state, payload) {
    state.jobRole = {
      name: "",
      description: "",
      jobTitles: [],
      targets: [],
    };
  },
  SET_PRODUCTS(state, payload) {
    state.products = payload;
  },
  SET_DRAFTS(state, payload) {
    state.drafts = payload;
  },
  SET_VERSION_HISTORY_PRODUCTS(state, payload) {
    state.versionHistoryProducts = payload;
  },
  SET_RETIRED_PRODUCTS(state, payload) {
    state.retiredProducts = payload;
  },
  SET_PRODUCT_DETAILS(state, payload) {
    state.productDetails = payload;
  },
  SET_PRODUCT_NAME(state, payload) {
    state.productDetails.name = payload;
  },
  SET_PRODUCT_VERSION_NOTES(state, payload) {
    state.productDetails.releaseNotes = payload;
  },
  SET_ARRIVED_FROM(state, payload) {
    state.arrivedFrom = payload;
  },
  SET_PRODUCT_NAME_FOR_PRODUCT(state, payload) {
    state.productDetails.productName = payload;
  },
  SET_PRODUCT_DESCRIPTION(state, payload) {
    state.productDetails.description = payload;
  },
  SET_TIME_ALLOCATION(state, payload) {
    state.productDetails.maxTimeAllocation = payload;
  },
  SET_ASSESSMENT_DURATION(state, payload) {
    state.productDetails.duration = payload;
  },
  SET_COMPETENCY_NAME(state, payload) {
    state.competency.name = payload;
  },
  SET_COMPETENCY_DESCRIPTION(state, payload) {
    state.competency.description = payload;
  },
  SET_COMPETENCY_COLOR(state, payload) {
    state.competency.color = payload;
  },
  SET_SUB_COMPETENCY(state, { value, index }) {
    state.competency.subCompetencies[index].name = value;
  },
  REMOVE_SUB_COMPETENCY(state, payload) {
    state.competency.subCompetencies.splice(payload, 1);
  },
  ADD_SUB_COMPETENCY(state) {
    state.competency.subCompetencies = [
      ...state.competency.subCompetencies,
      { name: "", id: Math.random().toString() },
    ];
  },
  CREATE_COMPETENCY(state, payload) {
    state.competency = payload;
  },
  SET_SURVEY_COMPETENCIES(state, payload) {
    state.surveyCompetencies = payload;
  },
  SET_COMPETENCY_DETAILS(state, payload) {
    state.competency = payload;
  },
  SET_COMPETENCY_STATUS(state, payload) {
    state.competency.active = payload;
  },
  SET_JOB_ROLE_NAME(state, payload) {
    state.jobRole.name = payload;
  },
  SET_JOB_ROLE_STATUS(state, payload) {
    state.jobRole = payload;
  },
  SET_JOB_ROLE_DESCRIPTION(state, payload) {
    state.jobRole.description = payload;
  },
  SET_JOB_TITLES(state, payload) {
    state.jobRole.jobTitles.push(payload);
  },
  CREATE_JOB_ROLE(state, payload) {
    state.jobRole = payload;
  },
  SET_JOB_ROLE_DETAILS(state, payload) {
    state.jobRole = payload;
  },
  SET_SCORE_NAME(state, payload) {
    state.scores[payload.index].name = payload.name;
  },
  SET_SCORE_VALUE(state, payload) {
    state.scores[payload.index].score = payload.score.label;
  },
  SET_SCORES(state, payload) {
    state.scores = payload;
  },
  RESET_SCORES(state) {
    state.scores = [];
  },
  SET_DEFAULT_QUESTIONS(state) {
    const compList = state.surveyCompetencies.map((comp) => {
      return comp.subCompetencies.map((subComp) => ({
        ...subComp,
        competencyName: comp.name,
        competencyColor: comp.color,
        competencyId: comp.id,
      }));
    });
    const mappedList = [];
    compList.forEach((comp) => {
      comp.forEach((subComp) => mappedList.push(subComp));
    });
    const list = mappedList.map((item) => {
      if (!item.question) {
        const resList = state.scores.map((item) => ({
          value: item.score,
          text: "",
        }));
        return {
          competencyName: item.competencyName,
          competencyColor: item.competencyColor,
          competencyId: item.competencyId,
          subCompetencyId: item.id,
          subCompetencyName: item.name,
          text: "",
          responses: resList,
        };
      } else {
        const responses = item?.question.responses;
        const allResponses = Array.from({ length: state.scores.length }, (_, i) =>
        responses?.find(x => x.value === i) || ({
            value: i,
            text: "",
          }));
        return {
          ...item?.question,
          responses: allResponses,
          competencyName: item.competencyName,
          competencyColor: item.competencyColor,
          competencyId: item.competencyId,
          subCompetencyId: item.id,
          subCompetencyName: item.name,
        };
      }
    });
    state.questions = list;
  },
  SET_FILTERED_QUESTIONS(state, payload) {
    state.questions = payload;
  },
  SET_QUESTION_TEXT(state, { id, text }) {
    state.questions.find((item) => item.subCompetencyId === id).text = text;
  },
  SET_RESPONSE_TEXT(state, { id, index, value }) {
    state.questions.find((item) => item.subCompetencyId === id).responses[
      index
    ].text = value;
  },
  SET_SURVEY_COMMUNICATION(state, payload) {
    state.communication = payload;
  },
  SET_COMMUNICATION_MESSAGE(state, { type, value }) {
    state.communication.find(
      (item) => item.surveyMessageType === type
    ).message = value;
  },
  SET_EMAIL_SUBJECT(state, { type, value }) {
    state.communication.find(
      (item) => item.surveyMessageType === type
    ).subject = value;
  },
  SET_EMAIL_AUDIENCE(state, { type, value }) {
    state.communication.find(
      (item) => item.surveyMessageType === type
    ).messageAudience = value;
  },
  SET_EMAIL_FREQUENCY(state, { type, value }) {
    const item = state.communication.find(
      (item) => item.surveyMessageType === type
    );
    item.nrDays = value;
    item.messageFrequency = +(value > 0)
  },
  SET_INTRO_IMAGE(state, { payload, type }) {
    const navProp = getImageDataByType(type).name;
    state[navProp] = payload;
  },
  RESET_INTRO_IMAGE(state) {
    state.introImage = null;
  },
  GET_ORG_TARGETS(state, payload) {
    const targetsList = payload.map((item) => ({
      competencyId: item.competencyId,
      competencyName: item.competencyName,
      ...item.targets[0],
    }));
    state.orgTargets = targetsList;
  },
};

const getters = {
  productVersions: (state) => state.productDetails.productVersions,
  publishDate: (state) => state.productDetails.publishDate,
  isReadOnly: (state) => state.productDetails.isReadOnly,
  getRoutePathToRedirectTo: (state) => {
    if (state.arrivedFrom == PlaceArrivedFromEnum.GLOBAL_JOB_ROLES)
      return "job-roles";
    if (state.arrivedFrom == PlaceArrivedFromEnum.GLOBAL_COMPETENCIES)
      return "competencies";
    return "products";
  },
  getRouteNameToRedirectTo: (state) => {
    if (state.arrivedFrom == PlaceArrivedFromEnum.GLOBAL_JOB_ROLES)
      return "Job Roles";
    if (state.arrivedFrom == PlaceArrivedFromEnum.GLOBAL_COMPETENCIES)
      return "Competencies";
    return "Products";
  }
};

export const PlaceArrivedFromEnum = {
  PRODUCTS: 'Products',
  GLOBAL_JOB_ROLES: 'GlobalJobRoles',
  GLOBAL_COMPETENCIES: 'GlobalCompetencies'
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
