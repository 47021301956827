const getDefaultState = () => ({
    isFromAllOpenAssessments: false,
    manageCommunicationBackRoute: '',
    editAssessmentBackRoute: '',
    companyOverviewBackRoute: '',
})

const actions = {
    setIsFromOpenAssessmentsPage({commit}, value) {
        commit('SET_IS_FROM_OPEN_ASSESSMENTS', value);
    },
}
const state = getDefaultState();

const mutations = {
    SET_IS_FROM_OPEN_ASSESSMENTS(state, value) {
      state.isFromAllOpenAssessments = value;
    },
}

const getters = {
    isFromAllOpenAssessments: (state) =>{console.log(state); return state.isFromAllOpenAssessments},
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };