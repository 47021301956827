<template>
  <div>
    <div class="flex">
      <p class="font-barlow font-medium text-xl text-grey-medium-2">
        {{ title }} {{ displayVersionForSeller(productVersion) }} /
      </p>
      <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
        Participants
      </p>
      <div class="flex flex-grow justify-end">
        <Button text="Back" type="primary-white" size="medium" :iconLeft="arrowLeft" :rotateArrow="true"
          :onClick="goBack" class="mr-2" data-testid="back-button" />
        <Button text="Next" type="primary" :iconRight="arrowRight" size="medium" :onClick="goNext"
          :disabled="participants.length === 0" data-testid="next-button" />
      </div>
    </div>
    <div v-if="companyHasUsers">
      <UserSelect />
    </div>
    <div v-else>
      <UserUpload :refreshUsers="refreshUsers" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import store from "@/store";
import Button from "../../../components/Button/Button.vue";
import UserUpload from "../components/UserUpload.vue";
import UserSelect from "../components/UserSelect.vue";
import arrowRight from "@/assets/img/icons/arrow-right-white.svg";
import arrowLeft from "@/assets/img/icons/arrow-right.svg";

export default {
  components: {
    Button,
    UserUpload,
    UserSelect,
  },
  name: "Participants",
  data: () => ({
    arrowLeft,
    arrowRight,
  }),
  computed: {
    ...mapState({
      title: (state) => state.assessmentSetup.assessmentTitle,
      id: (state) => state.assessmentSetup.cloneId,
      companyHasUsers: (state) => state.assessmentSetup.totalUserCount !== 0,
      participants: (state) => state.assessmentSetup.participants,
      participantsDirty: (state) => state.assessmentSetup.participantsDirty,
      shouldEdit: (state) => state.assessmentSetup.shouldEdit,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
      areNewParticipants: "assessmentSetup/areNewParticipants",
    }),
    assessmentType() {
      return this.generalInfo.surveyType;
    },
    productVersion() {
      return this.generalInfo.productVersion;
    },
  },
  async created() {
    await this.getUsersFromOrganizationCount({
      pageNr: 1,
      pageSz: 10,
    });
    this.setDirty({ key: "participantsDirty", value: false });
  },
  methods: {
    ...mapActions({
      getUsersFromOrganizationCount: "assessmentSetup/getUsersFromOrganizationCount",
      setDirty: "assessmentSetup/setDirty",
      openModal: "modals/openModal",
    }),
    async goNext() {
      if (this.assessmentType === 0)
        this.$router.push("/assessments/job-roles");
      if (this.assessmentType === 1) this.$router.push("/assessments/targets");
    },
    goBack() {
      this.$router.push("/assessments/general-information");
    },
    displayVersionForSeller(version) {
      if (version && version !== "" && (this.isImpersonating || this.isSeller))
        return " (v" + version + ")";
      return "";
    },
    async refreshUsers() {
      this.setDirty({ key: "participantsDirty", value: false });
      await this.getUsersFromOrganizationCount({
        pageNr: 1,
        pageSz: 20,
      });
    }
  },
  beforeRouteEnter(to, _, next) {
    const title = store.getters["assessmentSetup/getAssessmentTitle"];
    to.meta.sidenav.title = title + " Setup";
    next();
  },
  beforeRouteLeave(to, _, next) {
    if (this.shouldEdit && this.participantsDirty) {
      if (to.name === "JobRoles" || to.name === "Targets") {
        if (this.areNewParticipants) {
          return this.openModal({
            modal: "addingParticipants",
            props: { next },
          });
        } else {
          next();
        }
      } else {
        return this.openModal({ modal: "leaveParticipants", props: { next } });
      }
    } else {
      next();
    }
  },
};
</script>
