<template>
  <div class="bg-white px-2 pt-3 pb-4">
    <h2 class="font-bold text-mdl text-grey-light mb-1.5">Launched Products</h2>
    <p class="font-normal text-md text-grey-dark-1 mb-2">
      Products that are available to customers.
    </p>
    <p v-if="products.length === 0" class="text-mdlh text-grey-dark-1 font-medium mb-1 ml-2">
      No Products added yet
    </p>
    <AssessmentCardsContainer id="products" :cards="products" :showProductsCount="false" :cardsCount="products.length"
      class="container">
      <ProductCard v-for="survey in products" :key="survey.id" :survey="survey" :isViewable="true"
        :onClick="() => onProdClick(survey.id, survey.name)" 
        :onView="() => view(survey.id)" buttonText="Manage"
        :onRemove="() => onRetireClick(survey.id, survey.name)"
        :onClone="() => onClone(survey.id, survey.name)"
        :options="['CLONE', 'RETIRE']"
        class="flex-shrink-0">
        <!-- <Button text="Retire" size="medium" type="primary-white" class="absolute top-1 right-1"
          :onClick="() => onRetireClick(survey.id, survey.name)" /> -->
        <p v-if="survey.launchedOn" class="text-blue-light-5 text-sm-2 mb-0.5">
          Launched on {{ survey.launchedOn }}
        </p>
        <p v-if="survey.currentVersion" class="text-blue-light-5 text-sm-2">
          Version {{ survey.currentVersion }}
        </p>
      </ProductCard>
    </AssessmentCardsContainer>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AssessmentCardsContainer from "../../AdminAssessments/components/AssessmentCardsContainer.vue";
import ProductCard from "./ProductCard.vue";
import Button from "../../../components/Button/Button.vue";
import removeIcon from "@/assets/img/icons/removeBig.svg";
import { PlaceArrivedFromEnum } from '../store/index';

export default {
  name: "LaunchedProducts",
  components: { AssessmentCardsContainer, ProductCard, Button },
  data: () => ({ removeIcon }),
  computed: {
    ...mapState({
      products: (state) => state.products.products,
    }),
  },
  methods: {
    ...mapActions({
      getProducts: "products/getProducts",
      openModal: "modals/openModal",
      setArrivedToProductsEditFrom: "products/setArrivedToProductsEditFrom"
    }),
    onClone(id, name) {
      this.openModal({ modal: "cloneAssessmentModal", props: { id, productDraftName: `${name} - clone` } });
    },
    onProdClick(id, name) {
      this.openModal({ modal: "manageAssessmentModal", props: { id, productDraftName: name } });
    },
    onRetireClick(id, name) {
      this.openModal({ modal: "retireAssessmentModal", props: { id, productDraftName: name } });
    },
    view(id) {
      this.$router.push(`/products/${id}/dashboard`);
    }
  },
  mounted() {
    this.setArrivedToProductsEditFrom(PlaceArrivedFromEnum.PRODUCTS);
    this.getProducts();
  },
};
</script>
<style lang="scss">
.container {
  margin-bottom: 0 !important;
}
</style>
