<template>
  <Modal :data="$data" :isVisible="visible">
    <InputField label="Name" placeholder="E.x. Lorem Ipsum" :onChange="(value) => (productDraftName = value)"
      :defaultValue="productDraftName" class="mb-6" />
    <div class="flex flex-row flex-grow justify-end">
      <Button type="tertiary" size="medium" text="No" @click.native="closeModal" class="mr-2" />
      <Button type="primary" text="Yes" size="medium" @click.native="confirm" class="px-3" />
    </div>
  </Modal>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CloneAssessmentModal",
  props: ["visible", "id", "productDraftName"],
  data: () => ({
    title: "Clone Assessment",
    content:
      "When a product is cloned you need to define that product's name.",
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      openModal: "modals/openModal",
      cloneProduct: "products/cloneProduct",
      showError: "alerts/showError",
      resetProductDetails: "products/resetProductDetails",
    }),
    confirm() {
      this.cloneProduct({
        productId: this.id, name: this.productDraftName
      })
        .then((id) => {
          this.resetProductDetails();
          this.$router.push(`/products/${id}/dashboard`);
          this.closeModal();
        })
        .catch((err) => {
          var error = {
            messageHeader: "Error",
            message: err.message || "One version of this product already exists in product drafts.",
          };
          try {
            var parsedError = JSON.parse(err.toString().substring(err.toString().indexOf('{'), err.length))
            error.messageHeader = "Error";
            error.message = parsedError[0] || "One version of this product already exists in product drafts.";
          } catch { }
          this.showError(error);
        });
    },
  },
};
</script>
