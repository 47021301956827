<template>
    <Modal :data="$data" :isVisible="true" :hasCloseIcon="true">
        <p class="-mt-3 mb-2 text-blue-dark-4">
            We sent a verification code to {{ this.email }}. Please check your inbox and enter the code bellow.
        </p>
        <div class="flex flex-col font-main text-md text-blue-dark-4 mb-4">
            <InputField label="5-digits code" :onChange="onChangeCode" type="text" class="mb-3"
                :isTextOk="error ? false : null" errorText="Code not valid" />
            <div class="flex items-center -mt-2">Did not receive an email?
                <Button type="primary-white" text="Resend code" size="medium" @click.native="handleResendCode" />
            </div>
        </div>
        <Button type="primary" text="Verify code" class="w-full" size="medium" @click.native="handleAcceptCode" />
    </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import InputField from "../../../components/InputField/InputField.vue";

export default {
    name: 'EnterEmailConfirmationCodeModal',
    props: ['acceptCode', 'resendCode', 'email', 'password'],
    components: { InputField },
    data: () => ({
        title: 'Verify your email',
        code: '',
        error: false
    }),
    methods: {
        ...mapActions({
            closeModal: 'modals/closeModal',
            login: "auth/login",
            resendEmailConfirmationToken: "auth/resendEmailConfirmationToken",
        }),
        onChangeCode(value) {
            this.code = value;
        },
        async handleAcceptCode() {
            const response = await this.login({
                email: this.email,
                password: this.password,
                emailConfirmationCode: this.code,
            });
            console.log(response)
            if (response === -1) {
                this.error = true;
            } else {
                this.closeModal();
            }
        },
        async handleResendCode() {
            await this.resendEmailConfirmationToken({ email: this.email })
        }
    },
};
</script>