<template>
  <div>
    <div>
      <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
        Customer Companies
      </p>
      <div class="flex flex-row items-end justify-between">
        <div>
          <InputField
            :placeholder="inputPlaceholder"
            :label="inputLabel"
            :onChange="setKeyword"
            leftIcon
            class="mb-2 w-inputField"
            data-testid="filter"
          />
        </div>
        <div>
          <div class="mb-2">
            <Button
              text="New Company"
              type="primary"
              :iconLeft="add"
              size="medium"
              class="new-company-btn"
              @click.native="createCompany"
              data-testid="new-company-button"
            />
          </div>
        </div>
      </div>
      <p class="text-sm-2 text-grey-dark-2 mb-2">
        Showing {{ mappedList.length }} companies out of {{ totalCount }}
      </p>
      <div>
        <div
          class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded"
          :class="mappedList.length < pageSize ? '' : 'pr-2'"
        >
          <div class="flex flex-grow w-1/3" data-testid="header-1">
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="() => sortCol('NAME')"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">COMPANY NAME</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div class="flex w-1/2" data-testid="header-2">
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="sortCol('ADMIN')"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">ADMIN</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div class="flex flex-grow w-1/6" data-testid="header-2">
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="sortCol('SUBSCRIPTION')"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">SUBSCRIPTION</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[4] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div class="flex flex-grow w-1/6 pl-3.5" data-testid="header-3">
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="sortCol('USERS')"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">USERS</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div class="flex flex-grow w-1/6 px-3" data-testid="header-4">
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="sortCol('STATUS')"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <button
            class="flex flex-shrink justify-center text-md text-grey-dark-2 w-1/6"
            data-testid="header-5"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">ACTIONS</p>
          </button>
        </div>
        <div>
          <VirtualList
            style="max-height: 360px; overflow-y: auto"
            :data-key="'name'"
            :data-sources="mappedList"
            :data-component="itemComponent"
            data-testid="table"
            :extra-props="{ manage: doManage }"
            @tobottom="triggerFetch"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import debounce from "lodash/debounce";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import add from "@/assets/img/icons/add.svg";

export default {
  name: "CompaniesTable",
  components: { VirtualList },
  props: {
    inputLabel: { type: String, default: "Filter by" },
    inputPlaceholder: { type: String, default: "Search by name or admin" },
    usersCount: { type: Number },
    id: { type: Number, default: 1 },
  },
  data: () => ({
    add,
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1, 1],
    mappedList: [],
    pageNr: 1,
    pageSize: 10,
    sorting: null,
  }),
  mounted() {
    this.updateList();
  },
  watch: {
    keyword() {
      this.pageNr = 1;
      this.mappedList = [];
      this.updateList();
    },
  },
  computed: {
    ...mapState({
      companies: (state) => state.companies.companies,
      totalCount: (state) => state.companies.totalCount,
    }),
  },
  methods: {
    ...mapActions({
      getCompanies: "companies/getCompanies",
      openModal: "modals/openModal",
      setIsFromOpenAssessments: 'backRoutes/setIsFromOpenAssessmentsPage',
    }),
    doManage(id) {
      this.setIsFromOpenAssessments(false);
      this.$router.push(`/company/${id}/overview`);
    },
    createCompany() {
      this.openModal({
        modal: "createCompanyModal",
      });
    },
    async sortCol(col) {
      if (this.pageNr < Math.ceil(this.totalCount / this.pageSize)) {
        await this.fetchAllCompanies();
      }
      if (col !== this.col) {
        this.way = 1;
        this.col = col;
        this.rotationMatrix = [1, 1, 1, 1, 1];
      } else {
        this.way *= -1;
        switch (col) {
          case "NAME":
            this.rotationMatrix[0] *= -1;
            break;
          case "ADMIN":
            this.rotationMatrix[1] *= -1;
            break;
          case "USERS":
            this.rotationMatrix[2] *= -1;
            break;
          case "STATUS":
            this.rotationMatrix[3] *= -1;
            break;
          case "SUBSCRIPTION":
            this.rotationMatrix[4] *= -1;
            break;
          default:
            break;
        }
      }
      switch (col) {
        case "NAME":
          if (this.way === -1) return this.mappedList.reverse();
          return this.mappedList.sort((a, b) => a.name.localeCompare(b.name));
        case "ADMIN":
          if (this.way === -1) return this.mappedList.reverse();
          return this.mappedList.sort(
            (a, b) => a.admins.length - b.admins.length
          );
        case "SUBSCRIPTION":
          if (this.way === -1) return this.mappedList.reverse();
          return this.mappedList.sort(
            (a, b) => a.subscriptionStatus - b.subscriptionStatus
          );
        case "USERS":
          if (this.way === -1) return this.mappedList.reverse();
          return this.mappedList.sort((a, b) => a.usersCount - b.usersCount);
        case "STATUS":
          if (this.way === -1) return this.mappedList.reverse();
          return this.mappedList.sort((a, b) => a.active - b.active);
      }
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
    }, 500),
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.totalCount / 10)) {
        this.pageNr++;
        this.updateList();
      }
    },
    async updateList(reset) {
      if (reset) {
        this.mappedList = [];
        this.pageNr = 1;
        this.keyword = "";
      }
      const payload = {
        keyword: this.keyword,
        pageNumber: this.pageNr,
        pageSize: this.pageSize,
        sorting: this.sorting,
      };
      let companies = await this.getCompanies({ payload });
      let map = companies.map((item, idx) => ({
        ...item,
        isSelected: false,
        index: (this.pageNr - 1) * this.pageSize + idx,
      }));
      this.mappedList = [...this.mappedList, ...map];
    },
    async fetchAllCompanies() {
      this.pageNr = Math.ceil(this.totalCount / this.pageSize);
      const payload = {
        keyword: this.keyword,
        pageNumber: 1,
        pageSize: this.totalCount,
        sorting: this.sorting,
      };
      let companies = await this.getCompanies({ payload });
      let companiesList = companies.map((item, idx) => ({
        ...item,
        isSelected: false,
        index: idx,
      }));
      this.mappedList = [...companiesList];
    },
  },
};
</script>
<style lang="scss" scoped>
.new-company-btn {
  line-height: 1;
}
</style>
