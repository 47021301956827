import { apiCall, apiCallBlob } from "./api";

export const getProducts = () => apiCall("GET", "api/v1/Product");

export const getDrafts = () => apiCall("GET", "api/v1/Product/drafts");

export const getVersionHistoryProducts = () =>
  apiCall("GET", "api/v1/Product/version-history");

export const getRetiredProducts = () =>
  apiCall("GET", "api/v1/Product/retired");

export const retireProduct = (id) =>
  apiCall("PUT", `api/v1/Product/${id}/retire`);

export const saveProductDetails = (payload) =>
  apiCall("POST", "api/v1/Product", payload);

export const editProductDetails = (id, payload) =>
  apiCall("PUT", `api/v1/Product/${id}`, payload);

export const getProductById = (id, version = "") =>
  apiCall("GET", `api/v1/Product/${id}/with-survey/${version}`);

export const deleteDraftProduct = (id) =>
  apiCall("DELETE", `api/v1/Product/${id}`);

export const createCompetency = (payload) =>
  apiCall("POST", "api/v1/Competency/create_competency", payload);

export const getSurveyCompetencies = (id) =>
  apiCall("GET", `api/v1/Competency/${id}/survey-competencies`);

export const cloneCompetencies = (payload) =>
  apiCall("POST", "api/v1/Competency/clone_competencies", payload);

export const editCompetency = (id, payload) =>
  apiCall("PUT", `api/v1/Competency/${id}/edit`, payload);

export const deactivateCompetency = (id) =>
  apiCall("PUT", `api/v1/Competency/${id}/deactivate`);

export const activateCompetency = (id) =>
  apiCall("PUT", `api/v1/Competency/${id}/activate`);

export const getCompetencyById = (id) =>
  apiCall("GET", `api/v1/Competency/${id}`);

export const deleteCompetency = (id) =>
  apiCall("DELETE", `api/v1/Competency/${id}/delete`);

export const createJobRole = (payload) =>
  apiCall("POST", "api/v1/JobRole/create", payload);

export const editJobRole = (payload) =>
  apiCall("PUT", `api/v1/JobRole/update`, payload);

export const changeJobRoleStatus = (id, isActive) =>
  apiCall('PATCH', `api/v1/JobRole/${id}/change-status`, { isActive });

export const editJobRoleTargets = (id, payload) =>
  apiCall("PATCH", `api/v1/JobRole/${id}/update-targets`, { targets: payload });

export const getJobRoleById = (id) => apiCall("GET", `api/v1/JobRole/${id}`);

export const cloneJobRoles = (id, payload) =>
  apiCall("POST", `api/v1/JobRole/clone-for-survey/${id}`, payload);

export const deleteJobRole = (id) =>
  apiCall("DELETE", `api/v1/JobRole/${id}/delete`);

export const updateScoreSettings = (id, payload) =>
  apiCall("PUT", `api/v1/Survey/${id}/update-score-settings`, payload);

export const getScoreSettings = (id) =>
  apiCall("GET", `api/v1/Survey/${id}/score-settings`);

export const setCompetencyQuestion = (id, payload) =>
  apiCall("POST", `api/v1/Competency/${id}/competency_questions`, payload);

export const deleteSurvey = (id) =>
  apiCall("DELETE", `api/v1/Survey/${id}/scores-and-questions`);

export const uploadSurveyImage = (id, payload) =>
  apiCall("POST", `api/v1/Survey/${id}/upload-image`, payload);

export const getSurveyCommunication = (id) =>
  apiCall("GET", `api/v1/Survey/${id}/get-custom-survey-communication`);

export const updateSurveyCommunication = (id, payload) =>
  apiCall(
    "PUT",
    `api/v1/Survey/${id}/update-custom-survery-communication`,
    payload
  );

export const getSurveyImage = (id, type) =>
  apiCall("GET", `api/v1/Survey/${id}/${type}/image`);


export const deleteSurveyImage = (id, type) =>
  apiCall("DELETE", `api/v1/Survey/${id}/${type}/image`);

export const downloadSurveyImage = (id) =>
  apiCallBlob("GET", `api/v1/File/${id}`);

export const deleteCustomMessage = (id) =>
  apiCall("DELETE", `api/v1/Communication/custom-message/${id}`);

export const getOrgTargets = (id) =>
  apiCall("GET", `api/v1/Target/by_survey/organization/${id}`);

export const updateOrgTargets = (id, payload) =>
  apiCall("PUT", `api/v1/Target/${id}/add_update_org_targets`, payload);

export const publishProduct = (id, payload) =>
  apiCall("PUT", `api/v1/Product/${id}/publish`, payload);

export const editLaunchDate = (id, payload) =>
  apiCall("PUT", `api/v1/Product/${id}/edit-launch-date`, payload);

export const createNewVersion = (payload) =>
  apiCall("POST", "api/v1/Product/new-version", payload);

export const cloneProduct = (payload) =>
  apiCall("POST", "api/v1/Product/clone", payload);

export const getTimezones = () => apiCall("GET", "api/v1/Product/timezones");
