<template>
  <div>
    <InputField label="Password" :onChange="doChange" type="password" />
    <InputField label="Confirm Password" :onChange="doConfirmPasswordChange" type="password" class="mt-1" />
    <Validator :text="value" :confirmPasswordText="confirmPasswordValue"  :validate="validate" :noLabel='true'/>
  </div>
</template>
<script>
import Validator from "./Validator";
import InputField from "../InputField/InputField";
export default {
  name: "PasswordForm",
  components: { Validator, InputField },
  data() {
    return { value: "", confirmPasswordValue: "", isValid: false };
  },
  props: {
    setValidity: { type: Function, default: null },
    onChange: {
      type: Function,
      default: function(value) {
        this.value = value;
      },
    },
     onConfirmPasswordChange: {
      type: Function,
      default: function(value) {
        this.confirmPasswordValue = value;
      },
    },
  },
  methods: {
    doChange(val) {
      this.onChange(val);
      this.value = val;
    },
    doConfirmPasswordChange(val) {
      this.onConfirmPasswordChange(val);
      this.confirmPasswordValue = val;
    },
    validate(value) {
      this.isValid = value;
      this.setValidity(value);
    },
  },
};
</script>
