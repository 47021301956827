<template>
  <div class="space-y-2 pb-8">
    <p class="font-barlow font-bold text-xl text-grey-dark-1 pb-0.5">
      My Account
    </p>
    <div class="pt-2 px-2 bg-white rounded pb-4">
      <div class="flex-grow flex justify-between items-center">
        <p class="font-barlow font-bold text-mdl text-grey-light">
          General Information
        </p>
        <Button
          text="Save"
          type="primary"
          size="medium"
          :disabled="!$v.newPosition.required"
          :onClick="doPositionChange"
          data-testid="save-position-button"
        />
      </div>
      <div class="ml-3 mt-3">
        <div v-if="role === 'admin'" class="mb-3">
          <p class="text-sm-2 text-grey-light mb-0.4">Company Name</p>
          <p class="font-medium text-mdlh text-grey-dark-2">{{ company }}</p>
        </div>
        <div class="w-1/3 mb-1">
          <InputField
            label="Current Job Title"
            type="text"
            :defaultValue="position"
            :onChange="changePosition"
            data-testid="position-field"
          />
        </div>
        <p class="text-mdh text-grey-dark-1">
          Your position represents your current job title within your company.
        </p>
      </div>
    </div>
    <div class="pt-2 px-2 bg-white rounded">
      <div class="flex-grow flex justify-between items-center">
        <p class="font-barlow font-bold text-mdl text-grey-light">
          Change Password
        </p>
        <Button
          text="Save"
          type="primary"
          size="medium"
          :disabled="!this.validPassword"
          :onClick="doPassChange"
          data-testid="save-password-button"
        />
      </div>
      <div class="ml-3 pb-3">
        <p class="text-mdh text-grey-dark-1 mb-1.5">
          Please select a new password.
        </p>
        <div class="flex items-center">
          <div class="w-1/2 space-y-2">
            <InputField
              label="Current Password"
              type="password"
              :onChange="setCurrentPass"
              :propValue="currentPassword"
              class="w-2/3"
              data-testid="old-password-field"
            />
            <InputField
              label="New Password"
              type="password"
              :onChange="setNewPass"
              class="w-2/3"
              :propValue="newPassword"
              :isTextOk="
                currentPassword == ''
                  ? null
                  : !$v.newPassword.identical
                  ? null
                  : false
              "
              :errorText="errorText"
              data-testid="new-password-field"
            />
            <InputField
            label="Confirm New Password"
            type="password"
            :onChange="setConfirmNewPass"
            class="w-2/3"
            :propValue="confirmNewPassword"
            :errorText="errorText"
            data-testid="new-password-field"
          />
          </div>
          <Validator
            :text="newPassword"
            :confirmPasswordText="confirmNewPassword"
            :validate="validate"
            :noLabel="true"
            class="-mb-1.5"
            data-testid="validator"
          />
        </div>
      </div>
    </div>
    <div class="py-3 px-2 bg-white rounded">
      <div class="flex-grow flex justify-between items-center pb-2">
        <p class="font-barlow font-bold text-mdl text-grey-light">
          Data Management
        </p>
      </div>
      <div class="w-full h-line bg-grey-light-2" />
      <div class="flex-col flex">
        <div class="flex-row justify-between flex py-2 px-4">
          <div class="flex-col flex">
            <h2 class="text-grey-dark-1 text-mdh font-medium">
              My Personal Data
            </h2>
            <p class="text-grey-light text-sm">
              You have the right to download your personal data stored in the
              platform.
            </p>
          </div>
          <div>
            <Button
              text="Download Data"
              type="tertiary"
              size="medium"
              :onClick="downloadData"
              data-testid="data-download-button"
            />
          </div>
        </div>
        <div class="w-full h-line bg-grey-light-2" />
        <div class="flex-row justify-between flex py-2 px-4 items-center">
          <div class="flex-col flex">
            <h2 class="text-grey-dark-1 text-mdh font-medium">
              Delete My Personal Data
            </h2>
            <p class="text-grey-light text-sm">
              You have the right to delete your personal information from the
              platform.
            </p>
          </div>
          <div v-if="deletionRequested" class="flex-col delete-requested">
            <Button
              class="mx-auto"
              text="Request sent"
              type="tertiary"
              disabled
              size="medium"
            />
            <p class="text-sm-2 text-grey-medium-2">
              In review with Company Admin
            </p>
          </div>
          <div v-else="deletionRequested">
            <Button
              text="Request Deletion"
              type="tertiary-danger"
              size="medium"
              :onClick="deleteData"
              data-testid="save-password-button"
            />
          </div>
        </div>
        <div class="w-full h-line bg-grey-light-2" />
      </div>
    </div>
    <div class="flex w-full justify-end mx-auto pt-1 space-x-5">
      <a
        href="https://www.ismworld.org/footer/terms-of-use/"
        target="_blank"
        class="font-semibold text-md text-blue-dark-1"
      >
        Terms of Service
      </a>
      <a
        href="https://www.ismworld.org/footer/privacy-policy/"
        target="_blank"
        class="font-semibold text-md text-blue-dark-1"
      >
        Privacy Policy
      </a>
    </div>
    <!-- <div class="pt-2 px-2 pb-8 bg-white rounded">
      <div class="flex-grow flex justify-between items-center mb-1">
        <p class="font-barlow font-bold text-mdl text-grey-light">
          Notification Settings
        </p>
        <Button
          text="Save"
          type="primary"
          size="medium"
          :disabled="!saveNotifications"
          :onClick="doSetNotifications"
        />
      </div>
      <div class="border border-grey-light-2" />
      <div v-for="(item, index) in options" :key="index">
        <NotificationRow
          :title="item.title"
          :name="item.toggleName"
          :text="item.text"
          :index="index"
          class="px-2 py-2"
        />
        <div class="border border-grey-light-2" />
      </div>
    </div> -->
    <!-- <div class="flex flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        :text="buttonText"
        class="-mr-2"
        :onClick="open"
      />
    </div> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import debounce from "lodash/debounce";
import { saveAs } from "file-saver";
import Button from "../../../components/Button/Button.vue";
import InputField from "../../../components/InputField/InputField.vue";
import Validator from "../../../components/PasswordValidation/Validator.vue";
import NotificationRow from "../components/NotificationRow.vue";
import { required, sameAs } from "vuelidate/lib/validators";
import {
  getGdprData,
  reqeustDeletion,
  getPersonalData,
} from "../../../services/api/gdpr";

export default {
  components: { Button, InputField, Validator, NotificationRow },
  name: "MyAccount",
  data: () => ({
    validPassword: null,
    errorText:
      "Please make sure the password you enter has never been used before.",
    newPosition: "",
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    isValid: false,
    saveNotifications: false,
    deletionRequested: false,
    options: [
      {
        title: "New Assessment Alert",
        text: "Get notified of new assessments available for you",
        toggleName: "newAssessmentAlert",
      },
      {
        title: "Unfinished Assessment Reminder",
        toggleName: "unfinishedAssessmentAlert",
        text:
          "Get reminded of assessments that you need to finish. This reminder will be sent every 3 days until the assessment expires or you finish it.",
      },
      {
        title: "Upcoming Deadline Reminder",
        toggleName: "upcomingDeadlineAlert",
        text:
          "Get reminded of deadlines for assessments that you haven’t started. This reminder will be sent until the assessment expires your you finish it.",
      },
      {
        title: "New Results Alert",
        toggleName: "newResultsAlert",
        text:
          "Get notified of results that you have not seen yet. This notification will be sent after 5 and 10 days from results release.",
      },
    ],
  }),
  validations: {
    newPosition: {
      required,
    },
    currentPassword: { required },
    newPassword: {
      identical: sameAs("currentPassword"),
    },
  },
  computed: {
    ...mapState({
      alerts: (state) => state.account.alerts,
      requestStatus: (state) => state.account.requestStatus,
      position: (state) => state.home.position,
      email: (state) => state.home.email,
      id: (state) => state.home.userId,
      role: (state) => state.home.role,
      company: (state) => state.auth.decodedData.organizationName,
      companyId: (state) => state.auth.decodedData["organization-id"],
    }),
    buttonText() {
      return this.requestStatus ? "Delete Account" : "Request Account Removal";
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "account/SET_STATUS") {
        this.saveNotifications = true;
        this.unsubscribe();
      }
    });
  },
  mounted() {
    this.getDeletionStatus();
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      changePassword: "account/changePassword",
      changeJobTitle: "account/changePosition",
      setNotifications: "account/setNotifications",
      showSuccess: "alerts/showSuccess",
    }),
    open() {
      this.openModal({ modal: "requestDelete" });
    },
    changePosition: debounce(function(value) {
      this.newPosition = value;
    }, 500),
    setCurrentPass: debounce(function(value) {
      this.currentPassword = value;
    }, 500),
    setNewPass: debounce(function(value) {
      this.newPassword = this.isValid = value;
    }, 500),
    setConfirmNewPass: debounce(function(value) {
      this.confirmNewPassword = value;
    }, 500),
    validate(value) {
      this.isValid = value;
    },
    async getDeletionStatus() {
      const rsp = await getPersonalData(parseInt(this.id, 10));
      this.deletionRequested = rsp.data?.accountRequestType === 1;
    },
    downloadTemplate(blob, name) {
      const downloadLink = document.createElement("a");
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    async downloadData() {
      if (this.id) {
        const rsp = await getGdprData(parseInt(this.id, 10));
        const filename = rsp.headers["content-disposition"]
          .split('filename="')[1]
          .split('";')[0];
        saveAs(rsp.data, filename);
        if (rsp.status === 200) {
          this.showSuccess({
            messageHeader: "Download successful!",
            message: "The data has been downloaded in a .csv file format.",
          });
        }
      }
    },
    async deleteFn() {
      const res = await reqeustDeletion(
        parseInt(this.id, 10),
        parseInt(this.companyId, 10)
      );
      this.getDeletionStatus();
      if (res.status === 200) {
        this.showSuccess({
          messageHeader: "Request sent",
          message:
            "Your request to delete your personal data was successfully sent to your company administrator.",
        });
      }
    },
    deleteData() {
      this.openModal({
        modal: "dataDeletionModal",
        props: {
          deleteFn: this.deleteFn,
        },
      });
    },
    async doPassChange() {
      const res = await this.changePassword({
        email: this.email,
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      });
      if (res) {
        this.setCurrentPass("");
        this.setNewPass("");
        this.setConfirmNewPass("");
      }
    },
    doPositionChange() {
      this.changeJobTitle({ jobTitle: this.newPosition });
      this.newPosition = null;
    },
    doSetNotifications() {
      this.setNotifications();
    },
  },
  watch: {
    isValid() {
      if (
        !this.$v.newPassword.identical &&
        this.isValid &&
        this.currentPassword.length && 
        this.confirmNewPassword == this.newPassword
      ) {
        this.validPassword = true;
      } else {
        this.validPassword = false;
      }
    },
    currentPassword() {
      if (
        !this.$v.newPassword.identical &&
        this.isValid &&
        this.currentPassword.length
      ) {
        this.validPassword = true;
      } else {
        this.validPassword = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-requested {
  transform: translateX(12px);
}
</style>
