<template>
  <div class="w-card h-card pt-4 px-3 relative rounded bg-cover card"
    :style="{ background: `${imageOverlay}, ${url(bgImage)}` }">
    <div v-if="options.length" class="z-10 absolute top-1 right-1" @mouseover="visibleDropdown = true"
      @mouseleave="visibleDropdown = false">
      <Button text="Options" size="medium" type="primary-white" />

      <div class="absolute w-160 bg-white rounded border border-blue-dark-1 top-4 right-1"
        :class="visibleDropdown ? 'block' : 'hidden'">
          <Button text="Clone" size="medium" type="primary-white" class="w-full" v-if="options.includes('CLONE')" :onClick="onClone" />
          <Button text="Retire" size="medium" type="primary-white" class="w-full" v-if="options.includes('RETIRE')" :onClick="onRemove"/>
      </div>
    </div>
    <div class="flex flex-col justify-start pt-7">
      <p class="font-semibold text-mdlh mr-0.4 mb-1.5" :class="isPureDraft ? 'text-grey-light' : 'text-white'">
        {{ survey.name }}
      </p>
      <slot />
    </div>
    <div class="flex justify-between">
      <Button :text="buttonText" size="medium" type="primary-white" :onClick="onClick" />
      <IconButton v-if="isDraft" :icon="remove" :onClick="onRemove" />
      <Button v-if="isViewable" :text="'View'" size="medium" type="primary-white" :onClick="onView" />
    </div>
  </div>
</template>
<script>
import remove from "@/assets/img/icons/removeBig.svg";

import IconButton from "../../../components/IconButton/IconButton.vue";
import Button from "../../../components/Button/Button.vue";
import draft from "../../../assets/img/products/draft.png";
import getImageByColor from "../../../services/utils/getImageByColor";

export default {
  name: "ProductCard",
  components: { Button, IconButton },
  data: () => ({ remove, visibleDropdown: false }),
  props: {
    survey: { type: Object },
    isDraft: { type: Boolean, default: false },
    isViewable: { type: Boolean, default: false },
    onClick: { type: Function, default: () => { } },
    onRemove: { type: Function, default: () => { } },
    onClone: {type: Function, default: () => {} },
    onView: { type: Function, default: () => { } },
    buttonText: { type: String, default: "" },
    options: {type: Array, default: function () { return [] }}
  },
  computed: {
    bgImage() {
      const color = this.survey.color;
      if (this.isPureDraft) return draft;
      return getImageByColor(color);
    },
    isPureDraft() {
      return this.isDraft && !this.survey.latestVersion;
    },
    isVersionDraft() {
      return this.isDraft && this.survey.latestVersion;
    },
    imageOverlay() {
      if (this.isVersionDraft)
        return "linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%)";
      return "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%)";
    },
  },
  methods: {
    url(value) {
      return "url(" + value + ")";
    },
    toggleDropdown() {
      this.visibleDropdown = !this.visibleDropdown;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: grid;
  grid-template-rows: auto 54px;
}
</style>
